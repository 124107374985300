const MENU_HEADER = [
  {name : "About Us", path : "/about"},
  // {name : "Our Services", path : "/services"},
  {name : "Our Portfolio", path : "/portfolio"},
  {name : "Our Product", path : "/product"},
  {name : "Contact Us", path : "/contact"},
]

const MENU_FOOTER = [
  {name : "Home", path : "/"},
  {name : "About Us", path : "/about"},
  {name : "Our Products", path : "/product"},
  {name : "Our Services", path : "/services"},
  {name : "Contact Us", path : "/contact"},
  //{name : "Our Clients", path : "/services"},
  {name : "Get in Touch", path : "/contact"},
]

export {MENU_HEADER, MENU_FOOTER}