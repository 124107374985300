import { Container, Row, Col, Tabs, Tab, Nav, Image } from "react-bootstrap";
import Palette from "../utils/Palette";
import { RMS } from "../../dao/Services";
import Demo from "../../dao/Product";
import { Icon } from "@iconify/react";
import ServicesItem from "../reusable/ServicesItem";
import { Link, useOutletContext, useParams, useLocation } from "react-router-dom";

import PrimaryButton from "../reusable/PrimaryButton";
import { useEffect, useRef, useState } from "react";

export default function OurProduct() {

    useEffect(() => {
        window.scroll(0, 0);
    }, []);
    const location = useLocation();
    let { key } = useParams();
    console.log('isi key', key)
    const [activeTab, setActiveTab] = useState("irs")
    const [isMobile, setIsMobile] = useOutletContext()
    const demoRef = useRef(null)
    useEffect(() => {
        if (key) {
            setActiveTab(key)
            if (demoRef.current) {
                const itemToScrollTo = document.getElementById('curr-demo'); // Replace with your item's ID
                if (itemToScrollTo) {
                    itemToScrollTo.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    }, [])
    const detailsRender = (details) => {
        let mappedElements = details.map((el) => {
            return <ServicesItem el={el} />
        })

        let col1 =
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    marginBottom: '1rem',
                }}
            >
                {mappedElements.slice(0, 4)}
            </div>
        let col2 =
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    marginBottom: '1rem',
                }}
            >
                {mappedElements.slice(4, 8)}
            </div>

        let wholeSection =
            <Row>
                <Col md={4}>
                    {col1}
                </Col>
                <Col md={4}>
                    {col2}
                </Col>
                {!isMobile &&
                    <Col md={4} />
                }
            </Row>
        return wholeSection;
    }

    const RMSrenderer = () => {
        let mappedElements = RMS.map((el) => {
            return <ServicesItem el={el} />
        })

        let col1 =
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    marginBottom: '1rem',
                }}
            >
                {mappedElements.slice(0, 5)}
            </div>
        let col2 =
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    marginBottom: '1rem',
                }}
            >
                {mappedElements.slice(5, 10)}
            </div>

        let col3 =
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    marginBottom: '1rem',
                }}
            >
                {mappedElements.slice(10, 13)}
            </div>

        let wholeSection =
            <Row>
                <Col md={4}>
                    {col1}
                </Col>
                <Col md={4}>
                    {col2}
                </Col>
                <Col md={4}>
                    {col3}
                </Col>
            </Row>
        return wholeSection;
    }

    return (
        <div
            style={{
                color: Palette.WHITE,
                backgroundColor: Palette.GRAY_BACKGROUND,
            }}>
            <div
                style={{
                    backgroundColor: '#121212',
                    paddingTop: isMobile ? null : '2.75rem',
                    paddingLeft: isMobile ? '1rem' : '5rem',
                    paddingRight: isMobile ? '1rem' : '5rem',
                }}
            >
                <Tab.Container id="nav-demo-container" defaultActiveKey={key ? key : 'irs'}>
                    <Container>
                        <Nav className="nav-demo-list" variant="pills">
                            {
                                Demo.map((v) => {
                                    return (
                                        <>
                                            <Nav.Item>
                                                <Nav.Link style={{fontSize:'1.25em'}} eventKey={v.key}>{v.title}</Nav.Link>
                                            </Nav.Item>
                                        </>
                                    )
                                })
                            }
                        </Nav>
                    </Container>


                    <Container ref={demoRef}>
                        <div
                            style={{
                                paddingTop: '1.5rem',
                                paddingBottom: '3rem',
                                gap: '3rem',
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Tab.Content >

                                {
                                    Demo.map((v) => {
                                        return (
                                            <>
                                                <Tab.Pane eventKey={v.key}>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: '1rem',
                                                        }}>
                                                        <div
                                                            className="mt-4 mb-4"
                                                            style={{
                                                                textAlign: 'center',
                                                                fontWeight: 600,
                                                                color: Palette.WHITE
                                                            }}
                                                        >
                                                            <h2>{v.title}</h2>
                                                        </div>
                                                        <div

                                                            className="mt-3"
                                                            style={{
                                                                fontSize: '1.25em',
                                                                fontWeight: 400,
                                                                color: Palette.THEME_WHITE
                                                            }}
                                                        >
                                                            {v.description}
                                                        </div>
                                                        {
                                                            v?.details.length > 0 && <div>
                                                                {detailsRender(v.details)}
                                                            </div>
                                                        }

                                                    </div>

                                                    <div id="curr-demo" style={{ marginBottom: !isMobile ? 100 : 0 }}></div>

                                                    {
                                                        v?.productList?.length > 0 && v.productList.map((product, i) => {
                                                            {
                                                                product?.productList && <Row className={`mb-3 ${isMobile ? 'mt-3' : ''}`}>
                                                                    <div
                                                                        style={{
                                                                            textAlign: 'center',
                                                                            fontSize: isMobile ? '1.25em' : '1.25em',
                                                                            fontWeight: 600,

                                                                        }}
                                                                    >
                                                                        Try our Product Demo
                                                                    </div>
                                                                </Row>
                                                            }
                                                            return (
                                                                <>
                                                                    <Row style={{ marginBottom: 40, flexDirection: isMobile ? 'column-reverse' : 'row' }} className={`mt-4 ${i % 2 !== 0 ? 'flex-row-reverse' : ''}`}>
                                                                        <Col md={6} xs={12} sm={12}>
                                                                            <div
                                                                                className="mt-2 mb-2"
                                                                                style={{
                                                                                    fontSize: isMobile ? '1rem' : '1.25rem',
                                                                                    fontWeight: 600,

                                                                                }}
                                                                            >
                                                                                {product?.name}
                                                                            </div>
                                                                            <p style={{ fontSize: '1.25em', color: Palette.THEME_WHITE }}>
                                                                                {product?.description}
                                                                            </p>
                                                                            <div className={`mt-5 mb-${isMobile ? '5' : '2'}`}>
                                                                                <Link to={'/contact'}>
                                                                                    <PrimaryButton text={`Try Demo`} />
                                                                                </Link>
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <Image className="w-100" style={{ maxHeight: 400 }} src={product?.mainImage} fluid />
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )
                                                        })
                                                    }

                                                </Tab.Pane>

                                            </>
                                        )
                                    })
                                }


                            </Tab.Content>



                        </div>
                    </Container>
                </Tab.Container>

            </div>
        </div >
    )
}