import { Col, Container, Row, Image } from "react-bootstrap"
import PrimaryButton from "../reusable/PrimaryButton"
import Palette from "../utils/Palette"
import contact from "../../assets/Mapsicle Map.png"
import testimoniImage1 from "../../assets/testimoni/testimoni_1.png"
import testimoniImage2 from "../../assets/testimoni/testimoni_2.png"
import testimoniImage3 from "../../assets/testimoni/testimoni_3.png"
import testimoniImage4 from "../../assets/testimoni/testimoni_4.png"
import { useNavigate, useOutletContext } from "react-router-dom"
import noImage from '../../assets/no_image.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import aboutModel from '../../dao/AboutModel'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Navigation, Pagination, Scrollbar, A11y, Keyboard, Autoplay } from 'swiper/modules';
import { useEffect } from "react"
export default function AboutUs() {

    useEffect(() => {
        window.scroll(0, 0);
    }, []);
    const [isMobile, setIsMobile] = useOutletContext()
    const renderTestimonis = [
        {
            name: 'Ivan Lanin',
            image: testimoniImage4,
            company_name: 'Narabahasa',
            company_description: `Pelatihan dan Konsultasi Kebahasaan`,
            company_website: 'narabahasa.id',
            description: '"Intellivent sangat membantu Narabahasa dalam mengembangkan aplikasi pemesanan kelas Sinara dari konsep hingga kenyataan. Tim pengembang sangat responsif dan cepat mengeksekusi permintaan dukungan kami"'
        },
        // {
        //     name: 'Windy Ariestanty',
        //     image: testimoniImage1,
        //     company_name: 'patjarmerah',
        //     company_description: `We embrace technology to stay ahead of the curve, offering the latest advancements to elevate event experiences`,
        //     company_website: ' patjarmerah.com',
        //     description: '"Intellivent exceeded our expectations. Their technology and support made our event a tremendous success."'
        // },
        {
            name: 'Yani Kurniawan',
            image: testimoniImage2,
            company_name: 'Jaktent',
            company_description: `Festival Konten Kreatif Indonesia`,
            company_website: 'jaktent.com',
            description: '"Dengan tim yang profesional, komunikatif, kolaboratif, dan inovatif, Intellivent  berhasil menyelesaikan semua deliverables tepat waktu, juga dengan rancangan UI dan UX yang intuitif dan mudah digunakan, memastikan bahwa setiap peserta dapat menavigasi situs dengan mudah dan mendapatkan informasi yang mereka butuhkan tanpa hambatan. "'
        },
        {
            name: 'Ricky Rosano',
            image: testimoniImage3,
            company_name: 'CEO Aksara Kreativindo',
            company_description: null,
            company_website: null,
            description: '"Intelivent selalu menjadi partner  yang bisa diandalkan pada setiap event yang kami adakan, always fast respon & deliver the best quality. Keep the good works!"'
        },

    ]
    const navigate = useNavigate()
    const renderAbout = () => {
        return (
            <>
                <div className="hero-container">
                    <Container>
                        <Row>
                            <Col md={12}>
                                <div className="hero-title d-flex justify-content-center mb-2">
                                    <h1>
                                        About Us
                                    </h1>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container className="p-4">
                        <Row className="pt-5 pb-5">
                            <Col md={7}>
                                <div className="section-title-contact text-left mt-5 ">
                                    <h2 style={{ fontSize: '1.4em' }}>Our Story</h2>
                                </div>
                                <div style={{ color: Palette.THEME_WHITE, fontSize: '1.25em' }} className="mb-2">
                                    Envisioning a seamless and immersive event management future, we strive to be at the forefront of technological advancements. Our vision is to become the go-to platform for event organizers seeking efficiency, engagement, and success.

                                </div>
                            </Col>
                            <Col style={{ margin: '0 auto' }} md={3}>
                                <Swiper
                                    keyboard={{
                                        enabled: true,

                                    }}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{

                                        paginationClickable: true,
                                        dynamicBullets: true,
                                        clickable: true,
                                        keyboard: {
                                            enabled: true
                                        }
                                        // progressbarOpposite: true
                                    }}
                                    modules={[Navigation, Pagination, Scrollbar, A11y, Keyboard, Autoplay]}
                                    className="mySwiper"
                                >
                                    {
                                        aboutModel.map((thumb) => {
                                            return (
                                                <>
                                                    <SwiperSlide>
                                                        <Image style={{ width: '100%', maxHeight: 350, objectFit: 'cover' }} src={thumb} fluid></Image>
                                                    </SwiperSlide>
                                                </>
                                            )
                                        })
                                    }
                                </Swiper>
                            </Col>
                        </Row>
                    </Container>

                    <Container>
                        <Row>
                            <Col md={6}>
                                <div className="section-title-contact text-left mt-2">
                                    <h2 style={{ fontSize: '1.4em' }}>Our Vision</h2>
                                </div>
                                <div style={{ color: Palette.THEME_WHITE, fontSize: '1.25em' }} className="mb-2">
                                    Envisioning a seamless and immersive event management future, we strive to be at the forefront of technological advancements. Our vision is to become the go-to platform for event organizers seeking efficiency, engagement, and success.
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="section-title-contact text-left mt-2">
                                    <h2 style={{ fontSize: '1.4em' }}>Our Mission</h2>
                                </div>
                                <div style={{ color: Palette.THEME_WHITE, fontSize: '1.25em' }} className="mb-2">
                                    At Intellivent, our mission is to empower event organizers with the latest system and  technology stack. We are committed to deliver intuitive, user-friendly applications that enhance event experiences, foster meaningful connections, and surpass expectations.
                                </div>
                            </Col>
                        </Row>

                    </Container>


                </div>
                <div style={{ background: '#121212' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 80" fill="none">
                        <path width="100%" height="200" fillRule="evenodd" clipRule="evenodd" d="M0 17.7778L480 35.5556L960 26.6667L1440 0V80H960H480H0V17.7778Z" fill="white" fillOpacity="0.05" />
                    </svg>
                </div>
            </>


        )
    }

    const renderIntellivent = () => {
        return (

            <Container style={{ background: '#1e1e1e' }} fluid>
                <Container className={`pt-${isMobile ? 1 : 5} pb-5`}>
                    <div className="section-title mt-5 mb-5">
                        <h2>Why Choose Intellivent</h2>
                    </div>
                    <Row className="justify-content-center">
                        <Col md={3}>
                            <div className="section-title-contact text-left mt-5">
                                <h2 style={{ fontSize: '1.4em' }}>Innovation</h2>
                            </div>
                            <div style={{ color: Palette.THEME_WHITE, fontSize: '1.25em' }} className="mb-5">
                                We embrace technology to stay ahead of the curve, offering the latest advancements to elevate event experiences                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="section-title-contact text-left mt-5">
                                <h2 style={{ fontSize: '1.4em' }}>Collaboration</h2>
                            </div>
                            <div style={{ color: Palette.THEME_WHITE, fontSize: '1.25em' }} className="mb-5">
                                We believe in close collaboration with our clients, understanding their goals, and turning visions into reality                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="section-title-contact text-left mt-5">
                                <h2 style={{ fontSize: '1.4em' }}>Excellence</h2>
                            </div>
                            <div style={{ color: Palette.THEME_WHITE, fontSize: '1.25em' }} className="mb-5">
                                Our commitment to excellence drives us to deliver high-quality solutions and exceptional customer experiences                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="section-title-contact text-left mt-5">
                                <h2 style={{ fontSize: '1.4em' }}>Creativity</h2>
                            </div>
                            <div style={{ color: Palette.THEME_WHITE, fontSize: '1.25em' }} className="mb-5">
                                We infuse creativity into every aspect, crafting events that leave lasting impressions and captivate attendees                            </div>
                        </Col>
                    </Row>

                </Container>
            </Container>

        )
    }

    const renderPeople = () => {
        return (
            <div className="container-about">
                <Container className="p-0" fluid>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 200" fill="none">
                        <path width="100%" height="200" fillRule="evenodd" clipRule="evenodd" d="M0 120L640 40L1280 0V200H640H0V120Z" fill="#121212" />
                    </svg>
                </Container>
                <Container style={{ backgroundColor: '#121212' }} fluid>
                    <Container className="pt-5 pb-5">
                        <div className="section-title-about mt-3 mb-3">
                            <h2 style={{ fontSize: '1.4em' }}>People that Have Collaborated with Us</h2>
                        </div>
                        <div style={{ color: Palette.THEME_WHITE, fontSize: '1.25em' }} className="mb-5">
                            Discover the esteemed individuals and organizations that have trusted Intellivent to bring their events to life. Their success stories are a testament to our dedication and expertise.
                        </div>
                        <Row style={{ justifyContent: 'space-around' }} className="justify-content-center-about mt-5">
                            {
                                renderTestimonis?.map((v) => {
                                    return (
                                        <>
                                            <Col md={3}>
                                                <div className="pt-3 mt-3">
                                                    <Image
                                                        style={{ border: `4px solid ${Palette.ORANGE}`, width: 150, height: 150, borderRadius: '100%', objectFit: 'cover' }}
                                                        src={v.image} />
                                                    <div className="section-title-about-people text-left mt-5">
                                                        <h3 style={{ fontSize: '1.6em' }}>{v.name}</h3>
                                                    </div>
                                                    <div style={{ color: Palette.WHITE, fontSize: '1.25em' }} className="text-center">
                                                        {v.company_name}
                                                    </div>
                                                    <div style={{ color: Palette.WHITE, fontSize: '1em' }} className="text-center">
                                                        {v.company_description}
                                                    </div>
                                                    <div style={{ color: Palette.WHITE, fontSize: '0.9rem', fontStyle: 'italic' }} className="text-center">
                                                        {v.company_website}
                                                    </div>
                                                    <div style={{ color: Palette.THEME_WHITE, fontSize: '1.2em', fontStyle: 'italic' }} className="text-center mt-3">
                                                        {v.description}
                                                    </div>
                                                </div>
                                            </Col>
                                        </>
                                    )
                                })
                            }

                        </Row>
                    </Container>

                </Container>
            </div>
        )
    }

    const renderTalk = () => {
        return (
            <div className="container-about-talk">
                <Container className="p-0" style={{ padding: '0 !important', backgroundColor: '#121212' }} fluid>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 200" fill="none">
                        <path width="100%" height="200" fillRule="evenodd" clipRule="evenodd" d="M0 120L640 40L1280 0V200H640H0V120Z" fill="#414141" />
                    </svg>
                </Container>
                <Container style={{ backgroundColor: '#414141', marginTop: -20 }} fluid>
                    <Container className="pt-5 pb-5">
                        <div style={{ color: '#fff', fontSize: '1.25em', }} className="mb-5">
                            Let's collaborate on your next exceptional event. Reach out to us today to discuss how Intellivent can transform your vision into an extraordinary reality.                        </div>
                        <Row className="align-items-center mt-5">
                            <Col className=" mb-3" md={12}>
                                <PrimaryButton onClick={() => {
                                    navigate('/contact')
                                }} text={'LET’S TALK!'} />
                            </Col>

                        </Row>
                    </Container>
                </Container>
            </div>
        )
    }

    return (
        <>
            {renderAbout()}
            {renderIntellivent()}
            {renderPeople()}
            {renderTalk()}
        </>
    )
}