import { MENU_HEADER } from "../utils/Menu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Container, Col, Button, Navbar, Nav, Offcanvas } from "react-bootstrap"
import Palette from "../utils/Palette";
import { useState } from "react";
import Logo from "../../assets/Logo.png"

export default function Header({ isMobile }) {
  const [showMenu, setShowMenu] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  const fullwidthNav = () => {
    return (
      <>
        <div className={"d-flex flex-row align-items-center justify-content-center"} style={{
          color: "white",
          background: Palette.BLACK,
          height: 'auto',
          width: "100vw",
          position: "fixed", top: 0, zIndex: 999
        }}>
          <Container style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
            <Col className={"d-flex flex-row align-items-center justify-content-between"} xxl={12} style={{ height: 100 }}>
              <div>
                <Link to={'/'}>
                  <img className="image-logo" src={Logo} style={{ width: 'auto', height: '3.75rem' }} />
                </Link>

              </div>
              <div className={"d-flex flex-row"}>
                {
                  MENU_HEADER.map((obj => {
                    return (
                      <Link to={obj.path}>
                        <Button
                          className={"ms-3 me-3"}
                          style={{
                            fontWeight: 700,
                            color: location.pathname === obj.path ? Palette.ORANGE : Palette.WHITE,
                            cursor: "pointer",
                            borderColor: "transparent",
                            backgroundColor: 'transparent',
                            fontSize:'1.25em'
                          }}
                        >
                          {obj.name}
                        </Button>
                      </Link>
                    )
                  }))
                }
              </div>
              <div className={"ms-3"}>
                <Link to="/contact" style={{ textDecoration: "none", }}>
                  <Button
                    style={{
                      background: Palette.ORANGE,
                      borderColor: "transparent",
                      color: Palette.BLACK,
                      cursor: "pointer",
                      borderRadius: 0,
                      fontSize:'1.25em'
                    }}
                  >
                    Get In Touch
                  </Button>
                </Link>
              </div>
            </Col>
          </Container>
        </div>
      </>
    )
  }

  const mobileNav = () => {
    return (
      <>
        <Navbar collapseOnSelect style={{ background: Palette.BLACK }} key={showMenu} expand={false}>
          <Container fluid>
            <Navbar.Brand href="/"><img src={Logo} style={{ width: 'auto', height: '3.75rem' }} /></Navbar.Brand>
            <Navbar.Toggle onClick={() => {
              setShowMenu(!showMenu)
            }} style={{ border: 'none', color: '#fff' }} aria-controls="basic-navbar-nav">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M3 6H21V8H3V6ZM3 11H21V13H3V11ZM3 16H21V18H3V16Z" fill="white" />
              </svg>
            </Navbar.Toggle>
            <Offcanvas placement="end" show={showMenu} style={{ FontFamily: 'Lato', width: '100%' }}>
              <Offcanvas.Header style={{ background: Palette.BLACK, fontSize: 18, width: '100%' }}>
                <div style={{ display: 'flex', width: '100%', fontWeight: 600, fontSize: 18, color: 'white', alignItems: 'center', verticalAlign: 'center' }}>
                  <div style={{ flex: 1 }}><img src={Logo} style={{ width: 'auto', height: '3.75rem' }} /></div>
                  <div>
                    <Button
                      onClick={() => {
                        setShowMenu(!showMenu)
                      }}
                      style={{ color: 'white', fontSize: 20 }} variant="link"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M6 18L18 6M6 6L18 18" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </Button>
                  </div>
                </div>
              </Offcanvas.Header>
              <Offcanvas.Body style={{ background: Palette.BLACK }}>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  {
                    MENU_HEADER.map((obj => {
                      return <Nav.Link style={{ color: location.pathname === obj.path ? Palette.ORANGE : Palette.WHITE, fontFamily: 'Lato' }} onClick={() => {
                        navigate(obj.path)
                        setShowMenu(!showMenu)
                      }} href="javascript:;">{obj.name.toUpperCase()}</Nav.Link>
                    }))
                  }
                  <Button
                    style={{
                      marginTop: '3rem',
                      backgroundColor: Palette.ORANGE,
                      borderColor: 'transparent',
                      color: Palette.BLACK,
                      fontFamily: 'Lato',
                      borderRadius: 0,
                      fontWeight: 600,
                      fontSize: '0.875rem',
                    }}
                  >Get In Torch
                  </Button>
                </Nav>
              </Offcanvas.Body>
            </Offcanvas>
          </Container>
        </Navbar>
      </>
    )
  }

  const renderHandler = () => {
    return isMobile ? mobileNav() : fullwidthNav()
  }

  return (renderHandler())
}