import { Button } from "react-bootstrap";
import Palette from "../utils/Palette";
export default function PrimaryButton(props) {
    const { text } = props
    return (
        <>
            <Button
                {...props}
                style={{
                    background: Palette.ORANGE,
                    borderColor: "transparent",
                    color: Palette.BLACK,
                    cursor: "pointer",
                    borderRadius: 0,
                    fontSize:'1.25em'
                }}
            >
                {text}
            </Button>
        </>
    )
}