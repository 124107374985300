export default class Palette {
  static BLACK_BACKGROUND = "#121212"
  static BLACK = "#000000"
  static WHITE = "#FFFFFF"
  static ORANGE = "#F19509"
  static ORANGE_FADE = "rgba(241, 149, 9, 0.2)"
  static GRAY = "#CCCCCC"
  static GRAY_MORE = "#999999"
  static GRAY_BACKGROUND = "#121212"
  static GRAY_SMOOTH = "#E0E0E0"

  static THEME_BLUE = "#203965"
  static SHADOW = "#20396510"
  static THEME_ACCENT_BLUE = "#36528B"
  static THEME_INACTIVE_GREY = "#999999"
  static THEME_WHITE = "rgba(255, 255, 255, 0.80)"
}