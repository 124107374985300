
import tellinProject from '../assets/projects/tellin_project.png'
import tellinProject_1 from '../assets/projects/tellin_project_1.png'
import tellinProject_2 from '../assets/projects/tellin_project_2.png'
import tellinProject_3 from '../assets/projects/tellin_project_3.png'
import tellinProjectSmall from '../assets/projects/tellin_project_small.png'

import patjarmerahProject_1 from '../assets/projects/patjarmerah_project_1.png'
import patjarmerahProject_2 from '../assets/projects/patjarmerah_project_2.png'
import patjarmerahProject_3 from '../assets/projects/patjarmerah_project_3.png'
import patjarmerahProjectSmall from '../assets/projects/patjarmerah_project_small.png'

import sinaraProject from '../assets/projects/sinara_project.png'
import sinaraProject_1 from '../assets/projects/sinara_project_1.png'
import sinaraProject_2 from '../assets/projects/sinara_project_2.png'
import sinaraProject_3 from '../assets/projects/sinara_project_3.png'

import jaktentProject_1 from '../assets/projects/jaktent_project_1.png'
import jaktentProject_2 from '../assets/projects/jaktent_project_2.png'
import jaktentProject_3 from '../assets/projects/jaktent_project_3.png'
import jaktentProjectSmall from '../assets/projects/jaktent_project_small.png'

import islamFestProject from '../assets/projects/islam_fest_project.png'
import islamFestProject_1 from '../assets/projects/islam_fest_project_1.png'
import islamFestProject_2 from '../assets/projects/islam_fest_project_2.png'
import islamFestProject_3 from '../assets/projects/islam_fest_project_3.png'
import islamFestProjectSmall from '../assets/projects/islam_fest_project_small.png'
import sinaraProjectSmall from '../assets/projects/sinara_project_small.png'


import danamon_project from "../assets/projects/danamon_project.png"
import danamon_project_1 from "../assets/projects/danamon_project_1.png"
import danamon_project_2 from "../assets/projects/danamon_project_2.png"
import danamon_project_3 from "../assets/projects/danamon_project_3.png"
import danamonSmall from "../assets/projects/danamon_small.png"



import pet_fest_project from "../assets/projects/pet_fest_project.png"
import pet_fest_project_1 from "../assets/projects/pet_fest_project_1.png"
import pet_fest_project_2 from "../assets/projects/pet_fest_project_2.png"
import pet_fest_project_3 from "../assets/projects/pet_fest_project_3.png"
import pet_fest_project_small from "../assets/projects/pet_fest_project_small.png"



import sgu_project from "../assets/projects/sgu_project.png"
import sgu_project_1 from "../assets/projects/sgu_project_1.png"
import sgu_project_2 from "../assets/projects/sgu_project_2.png"
import sgu_project_3 from "../assets/projects/sgu_project_3.png"
import sgu_small from "../assets/projects/sgu_small.png"


import avatar from "../assets/projects/avatar_project.png"
import avatar_project_1 from "../assets/projects/avatar_project_1.png"
import avatar_project_2 from "../assets/projects/avatar_project_2.png"
import avatar_project_3 from "../assets/projects/avatar_project_3.png"
import avatar_project_small from "../assets/projects/avatar_project_small.png"


import cb_project_1 from "../assets/projects/cb_project_1.jpg"
import cb_project_3 from "../assets/projects/cb_project_3.jpg"
import cb_project_4 from "../assets/projects/cb_project_4.jpg"
import cb_project_small from "../assets/projects/cb_project_small.png"
import cb_project_video_1 from "../assets/projects/cb_project_video_1.mp4"
import cb_project_video_2 from "../assets/projects/cb_project_video_2.mp4"


const EVENT = 'event'
const EXIBITION = 'exibition'
const ACTIVATION = 'activation'
const listProject = [
    {
        title: 'Tellinverse',
        description: `We're partnering with Telin (PT Telekomunikasi Indonesia International) to build an exciting 3D virtual world that's unlike anything you've seen before. This advanced project will make Telin's ideas and services come alive in a boundless digital space, creating a special experience for users and stakeholders. Join us in creating this groundbreaking universe, where your imagination has no limits, and find a fresh way to connect and work with Telin's offerings.`,
        image: tellinProject,
        imageSmall: tellinProjectSmall,
        imageList: [
            tellinProject_1,
            tellinProject_2,
            tellinProject_3
        ],
        type: EXIBITION,
        selected: true,
        selectedOrder: 1,
        order: 2,
        url: 'https://telin-demo.intellivent.id/#/'
    },
    {
        title: 'Jaktent',
        description: `Jaktent is a festivel held by Yayasan Tujuhbelasribu Pulau Imaji and Frankfurt Book. We developed a website for event schedules and registration, where visitors can register for events and get tickets in the form of a QR that can be scanned on site`,
        image: jaktentProject_1,
        imageSmall: jaktentProjectSmall,
        imageList: [
            jaktentProject_1,
            jaktentProject_2,
            jaktentProject_3
        ],
        type: EVENT,
        selected: true,
        selectedOrder: 2,
        order: 1,
        url: 'https://2022.jaktent.com/'
    },
    {
        title: 'Patjarmerah',
        description: `Patjarmerah is a literacy festival and mobile book market that is held in various places in Indonesia. We built a mobile application and website dashboard to handle the participant registration system and other features to support the running of the event. This platform has been used by more than 25,000 users`,
        image: patjarmerahProject_1,
        imageSmall: patjarmerahProjectSmall,
        imageList: [
            patjarmerahProject_1,
            patjarmerahProject_2,
            patjarmerahProject_3
        ],
        type: EVENT,
        selected: true,
        selectedOrder: 3,
        order: 2,
        url: 'https://patjarmerah.com/'
    },
    {
        title: 'Sinara',
        description: `Sinara is an online educational platform from Narabahasa. Participants can register and enter two types of classes, public classes and independent online classes where participants can study learning material independently through videos, descriptions, practice materials, and quizzes`,
        image: sinaraProject,
        imageSmall: sinaraProjectSmall,
        imageList: [
            sinaraProject_1,
            sinaraProject_2,
            sinaraProject_3
        ],
        type: EVENT,
        selected: true,
        selectedOrder: 4,
        order: 3,
        url: 'https://sinara.narabahasa.id/'
    },
    {
        title: 'Islamic Book Fair',
        description: `We developed a mobile application for the 2019 and 2020 Islamic Book Fair. Participants can use the IBF Android application to get information about the event, booth information, maps and booths, read articles about the event, and play the QR treasure hunt game.`,
        image: islamFestProject,
        imageSmall: islamFestProjectSmall,
        imageList: [
            islamFestProject_1,
            islamFestProject_2,
            islamFestProject_3
        ],
        type: EVENT,
        order: 4,
        //url: '/portfolio'
    },
    {
        title: 'Danamon Fest',
        description: 'We created this virtual event together with our partners at primeskills. At this event, visitors can access a web version of the virtual lobby which contains minigames, as well as VR-based virtual events that were developed jointly with partners at primeskills.',
        image: danamon_project,
        imageSmall: danamonSmall,
        imageList: [
            danamon_project_1,
            danamon_project_2,
            danamon_project_3
        ],
        type: EXIBITION,
        order: 1,
        url: 'https://learningfest2022.intellivent.id/'
    },
    {
        title: 'Avatar Art Virtual',
        description: 'Within the scope of this project, we are engaged in the creation of browser-accessible, game-based virtual galleries that serve as platforms for art enthusiasts. These virtual galleries offer participants the unique opportunity to peruse and admire the artwork on display, which is thoughtfully curated and presented on behalf of our valued clients.',
        image: avatar,
        imageSmall: avatar_project_small,
        imageList: [
            avatar_project_1,
            avatar_project_2,
            avatar_project_3
        ],
        type: EXIBITION,
        order: 3,
        //url: 'https://intellivent.id/#/avatar-art-demo'
    },
    {
        title: 'SGU Open House',
        description: 'We develop game-based virtual events that can be accessed in a browser. In this virtual exhibition, visitors can interact with virtual booths, watch videos, play mini games and visit other participants.',
        image: sgu_project,
        imageSmall: sgu_small,
        imageList: [
            sgu_project_1,
            sgu_project_2,
            sgu_project_3
        ],
        type: EXIBITION,
        order: 4,
        url: 'https://sgu-virtual.intellivent.id/'
    },
    {
        title: 'Petfest',
        description: `At the virtual event, Petfest 2021, visitors can have a great time exploring a digital place that's like a hall. Inside this digital hall, there are 60 different booths, each offering interesting things to check out. You can also enjoy a carefully put together digital exhibition that will make your experience engaging and enjoyable.`,
        image: pet_fest_project,
        imageSmall: pet_fest_project_small,
        imageList: [
            pet_fest_project_1,
            pet_fest_project_2,
            pet_fest_project_3
        ],
        type: EXIBITION,
        order: 5,
        url: 'https://petfest.intellivent.id/ '
    },
    {
        title: 'CB Fest 2022',
        description: `At the event CB Fest 2022, we work with Bank Indonesia to create several games as an event gimmick to increase interaction in the booth. Each game is approximately 1 minute long, and the user with the highest score will get rewards.`,
        video1: cb_project_video_1,
        video2: cb_project_video_2,
        imageSmall: cb_project_small,
        imageList: [
            cb_project_1,
            cb_project_4,
            cb_project_3
        ],
        type: ACTIVATION,
        order: 1,
        url: ''
    },

]

export default listProject