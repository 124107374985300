import React, { Component, useState } from 'react';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
export default function FloatingWhatsapp() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 576)
    return (
        <>
            {
                !isMobile ? <Link to={'/whatsapp'} className="floating-whatsapp" target="_blank">
                    <Icon style={{ fontSize: 20, marginRight: 2 }} icon="ic:outline-whatsapp" className="icon" />  Chat Via WA
                </Link> : <Link to={'/whatsapp'} className="floating-whatsapp-mobile" target="_blank">
                    <Icon style={{ fontSize: 20, marginTop: 2 }} icon="ic:outline-whatsapp" className="icon" />
                </Link>
            }

        </>
    )
}
