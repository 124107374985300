
import irsIlust1 from '../assets/ilustrations/irs_ilust_1.png'
import irsIlust2 from '../assets/ilustrations/irs_ilust_2.png'
import irsIlust3 from '../assets/ilustrations/irs_ilust_3.png'


import irsSystem from '../assets/products/irs_system.png'
import irsDashboard from '../assets/products/irs_dashboard.png'

const Demo = [
    {
        id: 1,
        key: 'irs',
        title: 'Event Registration and Management System',
        description: "Our Event Registration & Management System offers a comprehensive solution to streamline the registration process and effectively manage your events. Take advantage of the following feature packages:",
        demoUrl: '/product/irs',
        demoDescription: 'Intellivent Registration System (IRS) is a comprehensive solution for smoothly managing attendee registration. With advanced features like easy registration, QR Code-based tickets, and a data monitoring dashboard, Intellivent makes event management efficient.',
        image: irsIlust1,
        demoThumbnails: null,
        details: [
            { name: "Attendee registration", icon: "mdi:users" },
            { name: "Quota management", icon: "eos-icons:quota" },
            { name: "Zoom integration", icon: "cib:zoom" },
            { name: "Automatic email", icon: "ic:baseline-email" },
            { name: "Certificate generator", icon: "mdi:certificate" },
            { name: "Payment integration", icon: "ic:baseline-payments" },
            { name: "Data gathering", icon: "mdi:database-plus" },
            { name: "Gamification", icon: "mingcute:game-2-fill" },
            { name: "Calendar integration", icon: "solar:calendar-bold" },
            { name: "Content dashboard", icon: "material-symbols:dashboard" },
            { name: "QR Code integration", icon: "ion:qr-code" },
            { name: "Upload materials", icon: "ri:folder-upload-fill" },
            { name: "Interactive maps", icon: "ri:road-map-fill" },
        ],
        productList: [
            {
                name: 'Intellivent Registration System',
                mainImage: irsSystem,
                description: `Experience the simplicity and efficiency of our Website Registration system. From smooth attendee sign-ups to QR Code-based entry tickets, we've got you covered.`,
                demoUrl: '#'
            },
            {
                name: 'Intellivent Registration System Dashboard',
                mainImage: irsDashboard,
                description: `Stay on top of your event's performance with our comprehensive Dashboard Monitoring. Gain real-time insights and effectively manage attendee data.`,
                demoUrl: '#'
            }
        ]
    },
    {
        id: 2,
        key: 'event3D',
        title: 'Event 3D Virtual',
        description: "Immerse your attendees in captivating 3D exhibition experiences with our Event 3D Exhibition service. Engage your audience with interactive features and customized designs. Explore the feature packages below:",
        demoUrl: '/product/event3D',
        demoDescription: 'Our Intellivent 3D Virtual Event (IVE) product is an innovative platform that brings your events to life in a captivating digital environment. With stunning 3D visuals and interactive features, attendees can network, explore virtual event spaces, and engage with content, all from their own devices.',
        demoThumbnails: null,
        image: irsIlust2,
        details: [
            { name: "Attendee registration", icon: "mdi:users" },
            { name: "Automatic email", icon: "ic:baseline-email" },
            { name: "Data gathering", icon: "mdi:database-plus" },
            { name: "Chatting and emoticons", icon: "mdi:chat" },
            { name: "Custom minigame", icon: "mingcute:game-2-fill" },
            { name: "Multiplayer", icon: "game-icons:tabletop-players" },
            { name: "Multimedia & video", icon: "mdi:multimedia" },
            { name: "Custom venue & character", icon: "mdi:account-edit" },
        ]
    },
    {
        id: 3,
        key: 'virtualReality',
        title: 'Virtual Reality',
        description: "Step into the realm of Virtual Reality and deliver immersive experiences like never before. Our Virtual Reality service brings simulations to life.",
        demoUrl: '/product/virtualReality',
        demoDescription: 'Intellivent Virtual Reality (IVR) seamlessly blends the digital and physical worlds to provide an immersive and interactive experience that enhances real-world environments with digital elements.        ',
        demoThumbnails: null,
        image: irsIlust3,
        details: [
            { name: "Attendee registration", icon: "mdi:users" },
            { name: "Automatic email", icon: "ic:baseline-email" },
            { name: "Data gathering", icon: "mdi:database-plus" },
            { name: "Chatting and emoticons", icon: "mdi:chat" },
            { name: "Custom minigame", icon: "mingcute:game-2-fill" },
            { name: "Multiplayer", icon: "game-icons:tabletop-players" },
            { name: "Multimedia & video", icon: "mdi:multimedia" },
            { name: "Custom venue & character", icon: "mdi:account-edit" },
        ]
    }
]
// const V3D = [
//     { name: "Attendee registration", icon: "mdi:users" },
//     { name: "Automatic email", icon: "ic:baseline-email" },
//     { name: "Data gathering", icon: "mdi:database-plus" },
//     { name: "Chatting and emoticons", icon: "mdi:chat" },
//     { name: "Custom minigame", icon: "mingcute:game-2-fill" },
//     { name: "Multiplayer", icon: "game-icons:tabletop-players" },
//     { name: "Multimedia & video", icon: "mdi:multimedia" },
//     { name: "Custom venue & character", icon: "mdi:account-edit" },
// ]
export default Demo 