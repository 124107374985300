import Palette from "../utils/Palette"
import { Icon } from "@iconify/react"

export default function ServicesItem({ el, boxStyle, containerStyle }) {
  return (
    <div
      style={
        {
          ...{
            display: 'flex',
            alignItems: 'center',
            gap: '0.75rem',
            alignSelf: 'stretch',
          }, ...containerStyle
        }}
    >
      <div
        style={
          {
            ...{
              width: '1.75rem',
              height: '1.75rem',
              padding: '0.5rem',
              display: "flex",
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: Palette.ORANGE_FADE,
              color: Palette.ORANGE,
              borderRadius: '0.5rem'
            },
            ...boxStyle
          }}
      >
        <Icon icon={el.icon} />
      </div>
      <div
        style={{
          fontSize: '1em',
          fontWeight: 400,
          color: Palette.WHITE,
        }}
      >
        {el.name}
      </div>
    </div>
  )
}