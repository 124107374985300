import { Badge, Card, Col, Container, Image, Row } from "react-bootstrap";
import PrimaryButton from "../reusable/PrimaryButton";
import Palette from "../utils/Palette";
import { Link, useLocation, useOutletContext } from "react-router-dom";
import { useEffect, useRef } from "react";
import { MediaPlayer, MediaProvider } from '@vidstack/react';
import { defaultLayoutIcons, DefaultVideoLayout } from '@vidstack/react/player/layouts/default';
import mainVideo from '../../assets/mainvideo.mp4'
import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import productData from '../../dao/Product'
import clientData from '../../dao/Client'
import eventData from '../../dao/Project'
import aboutModel from '../../dao/AboutModel'
import homepageBanner_1 from '../../assets/banner/homepage_banner_1.jpg'
import homepageBanner_2 from '../../assets/banner/homepage_banner_2.jpg'
import homepageBanner_3 from '../../assets/banner/homepage_banner_3.png'
import homepageBanner_4 from '../../assets/banner/homepage_banner_4.png'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Navigation, Pagination, Scrollbar, A11y, Keyboard, Autoplay } from 'swiper/modules';
export default function Home() {

    const location = useLocation()
    useEffect(() => {
        if (location.pathname === '/whatsapp') {
            window.location.href = 'https://api.whatsapp.com/send?phone=6281383798649'
        }
        window.scroll(0, 0)


    }, []);
    const [isMobile, setIsMobile] = useOutletContext()



    const renderHero = () => {
        return (
            <div className="hero-container-home">
                <Container>
                    <Row>
                        <Col md={8}>
                            <div className="hero-title-home d-flex justify-content-center">
                                {
                                    isMobile ? <h1>
                                        Digital Solution for Your Event
                                    </h1> : <h1>
                                        Digital Solution for Your  <br></br> Event
                                    </h1>
                                }
                            </div>

                        </Col>
                        <Col md={4}>
                            <div style={{ color: Palette.THEME_WHITE }} className="hero-description">
                                We develop applications and digital solutions to support your online and offline events.
                                <div className="mt-5">
                                    <Link to={'/contact'}>
                                        <PrimaryButton text={`Let's Talk`} />
                                    </Link>

                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>
        )
    }

    const renderVideo = () => {
        return (

            <MediaPlayer autoplay muted loop title="Intellivent Registration System" src={mainVideo}>
                <MediaProvider />
                <DefaultVideoLayout icons={defaultLayoutIcons} />
            </MediaPlayer>

        )
    }

    const renderAbout = () => {
        return (
            <>
                <Container className="p-0" style={{
                    background: Palette.BLACK_BACKGROUND
                }} fluid>
                    <Container>
                        <Row className="align-items-center">
                            <div className="section-title mb-5 mt-5">
                                <h2>About Us</h2>
                            </div>
                            <div style={{ color: Palette.THEME_WHITE }} className="text-center mb-3">

                                <p style={{ fontSize: '1.25em' }}> Intellivent, founded in 2019, is dedicated to improve the Meetings, Incentives, Conferences, and Exhibitions (MICE) industry. We simplify event planning and execution by seamlessly integrating technology and event management. Our mission is to lead the way in evolving MICE, offering custom technology-enhanced experiences for organizers and attendees.</p>
                                <p style={{ fontSize: '1.25em' }}>

                                    We understand the challenges event professionals face and the changing expectations of participants. Intellivent sets a new standard for MICE with advanced event management software and interactive tools, pushing the boundaries of what's possible. Join us on our journey to shape the future of MICE, and together, we'll create extraordinary and memorable events. Welcome to Intellivent, where technology and event expertise come together to make your MICE efforts outstanding.
                                </p>
                            </div>
                        </Row>
                        <Row className="align-items-center">

                            <Swiper

                                keyboard={{
                                    enabled: true,

                                }}



                                pagination={{

                                    paginationClickable: true,
                                    dynamicBullets: true,
                                    clickable: true,
                                    keyboard: {
                                        enabled: true
                                    }
                                    // progressbarOpposite: true
                                }}
                                modules={[Navigation, Pagination, Scrollbar, A11y, Keyboard, Autoplay]}
                                className="mySwiper"
                            >
                                {
                                    [homepageBanner_1, homepageBanner_2, homepageBanner_3, homepageBanner_4]?.map((thumb) => {
                                        return (
                                            <>
                                                <SwiperSlide>
                                                    <Card.Img style={{ borderRadius: 0, height: 680, objectFit: 'cover' }} src={thumb} fluid />
                                                </SwiperSlide>
                                            </>
                                        )
                                    })
                                }
                            </Swiper>

                            <Col className="mt-5 mb-3 text-center" md={12}>
                                <Link to={'/about'}>
                                    <PrimaryButton text={'SEE MORE'} />
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 1280 200" fill="none">
                        <path width="100%" height="100%" fillRule="evenodd" clipRule="evenodd" d="M0 44.4444L426.667 88.8889L853.333 66.6667L1280 0V200H853.333H426.667H0V44.4444Z" fill="#000" />
                    </svg>
                </Container>

            </>
        )
    }

    const renderProduct = () => {
        return (
            <>

                <Container>
                    <Row className="align-items-center mb-3">
                        <div className="section-title mt-5">
                            <h2>Our Products</h2>
                        </div>
                        <div style={{ color: Palette.THEME_WHITE, fontSize: '1.25em' }} className="text-center">
                            Introducing our cutting-edge product, which demonstrates our ability to provide solutions customized to your requirements and showcases our expertise.
                        </div>
                    </Row>
                    <Row>
                        {
                            productData.map((v) => {
                                return (
                                    <Col className={isMobile ? 'mb-3' : ''} md={4} sm={12}>
                                        <Card
                                            style={{ background: Palette.BLACK_BACKGROUND }}
                                        >
                                            <Card.Img src={v.image} />
                                            <Card.Header>
                                                <h4 className="py-3" style={{
                                                    fontSize: '1.2em',
                                                    fontWeight: 800,
                                                    color: Palette.THEME_WHITE,
                                                }}>
                                                    {v.name}
                                                </h4>
                                            </Card.Header>
                                            <Card.Body>
                                                <p style={{ fontSize: '1.25em', color: Palette.GRAY_SMOOTH, lineHeight: 2 }}>
                                                    {v.demoDescription}
                                                </p>
                                            </Card.Body>
                                            <Card.Footer>
                                                <div>
                                                    <Link style={
                                                        {
                                                            textDecoration: 'none',
                                                            fontSize: '1.25em',
                                                            fontWeight: 800,
                                                            color: Palette.ORANGE
                                                        }
                                                    } to={v.demoUrl}>Learn More</Link>
                                                </div>

                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                )
                            })
                        }

                    </Row>
                </Container>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 200" fill="none" preserveAspectRatio="none">
                    <path width="100%" height="100%" fillRule="evenodd" clipRule="evenodd" d="M0 120L640 40L1280 0V200H640H0V120Z" fill="#F7F7F8" />
                </svg>
            </>
        )
    }

    const renderClientPartner = () => {
        return (
            <>
                <Container style={{ marginTop: -50 }}>
                    <Row className="align-items-center">
                        <div className="section-title mb-5">
                            <h2 style={{ color: Palette.BLACK }}>Our Client & Partner</h2>
                        </div>
                    </Row>
                    <Row style={{ alignItems: 'center' }} className={isMobile ? `justify-content-center` : ''}>
                        {
                            clientData.map((v) => {
                                return (
                                    <Col className={`text-center ${isMobile ? 'w-auto mb-4' : ''}`} md={4}>
                                        <Image style={{ maxWidth: isMobile ? 110 : 200 }} alt={`client-${v?.title}`} fluid src={v?.image} />
                                    </Col>
                                )

                            })
                        }

                    </Row>
                </Container>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 200" fill="none" preserveAspectRatio="none">
                    <path width="100%" height="200" fillRule="evenodd" clipRule="evenodd" d="M0 0L640 40L1280 80V200H640H0V0Z" fill="#121212" />
                </svg>
            </>
        )
    }

    const renderSelectedProject = () => {
        return (
            <Container style={{ marginTop: -10 }}>
                <Row className="align-items-center">
                    <div className="section-title">
                        <h2>Selected Project</h2>
                    </div>
                    <div style={{ color: '#fff', fontSize: '1.25em' }} className="text-center mb-2">
                        Here are a few examples of our best work. These selections highlight our top achievements.
                    </div>
                </Row>
                <Row className={`mt-1 ${isMobile ? 'p-2' : 'p-5'}`}>
                    {
                        eventData.filter(v => v.selected).map((v, i) => {
                            return (
                                <>
                                    <Col md={12} className="mb-5">

                                        <Card style={{ border: 0, borderRadius: 0, background: '#1e1e1e' }}>
                                            <Row className={isMobile ? 'flex-column-reverse' : i % 2 !== 0 ? 'flex-sm-row-reverse' : ''}>
                                                <Col md={6}>
                                                    <Card.Header style={{ background: 'transparent', border: 0 }}>
                                                        <Card.Title className="mt-2" style={{ fontWeight: 'bolder', color: Palette.GRAY_SMOOTH, fontSize: '1.4em' }}>
                                                            {v.title}
                                                        </Card.Title>
                                                        {
                                                            v.type === 'event' ? <Badge>IRS</Badge> : <Badge bg="danger" variant="danger">IVE</Badge>
                                                        }

                                                    </Card.Header>
                                                    <Image src={v.imageSmall}
                                                        style={{
                                                            width: isMobile ? 120 : 'auto',
                                                            height: isMobile ? 120 : 'auto',
                                                            right: i % 2 == 0 ? 'auto' : 0,
                                                            position: "absolute",
                                                            zIndex: 1,
                                                            opacity: 0.2,

                                                        }}
                                                    />
                                                    <Card.Body style={{ color: Palette.THEME_WHITE }} className="h-100">

                                                        <div style={{ height: '70%', zIndex: 2, fontSize: '1.25em' }}>
                                                            {v.description}
                                                        </div>
                                                        <div>
                                                            <Link target={'_blank'} style={
                                                                {
                                                                    textDecoration: 'none',
                                                                    fontSize: '1.25em',
                                                                    fontWeight: 800,
                                                                    color: Palette.ORANGE
                                                                }
                                                            } to={v.url}>See Demo</Link>
                                                        </div>
                                                    </Card.Body>

                                                </Col>
                                                <Col md={6}>
                                                    <Swiper
                                                        keyboard={{
                                                            enabled: true,

                                                        }}
                                                        autoplay={{
                                                            delay: 3000 + (i * 100) + 500,
                                                            disableOnInteraction: false,
                                                        }}
                                                        pagination={{

                                                            paginationClickable: true,
                                                            dynamicBullets: true,
                                                            clickable: true,
                                                            keyboard: {
                                                                enabled: true
                                                            }
                                                            // progressbarOpposite: true
                                                        }}
                                                        modules={[Navigation, Pagination, Scrollbar, A11y, Keyboard, Autoplay]}
                                                        className="mySwiper"
                                                    >
                                                        {
                                                            v.imageList ? v.imageList?.map((thumb) => {
                                                                return (
                                                                    <>
                                                                        <SwiperSlide>
                                                                            <Card.Img style={{ borderRadius: 0 }} src={thumb} fluid />
                                                                        </SwiperSlide>
                                                                    </>
                                                                )
                                                            }) : <Card.Img style={{ borderRadius: 0 }} src={v.image} fluid />
                                                        }
                                                    </Swiper>

                                                </Col>
                                            </Row>

                                        </Card>


                                    </Col>
                                </>
                            )
                        })
                    }

                </Row>
            </Container >
        )

    }

    return (
        <>

            <Container style={{
                background: Palette.BLACK_BACKGROUND
            }} className="p-0" fluid>
                {renderHero()}
                {renderVideo()}
            </Container>

            {renderAbout()}
            <Container className="p-0" style={{
                background: Palette.BLACK
            }} fluid>
                {/* {renderService()} */}
                {renderProduct()}
            </Container>
            <Container className="p-0" fluid style={{ background: '#F7F7F8', marginTop: -10 }}>
                {renderClientPartner()}
            </Container>
            <Container style={{
                background: Palette.BLACK_BACKGROUND
            }} fluid>
                {renderSelectedProject()}
            </Container>

        </>
    )
}