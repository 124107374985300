import _ from "lodash";
import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Container, Image, Modal, Row } from 'react-bootstrap';
import { Link, useOutletContext } from "react-router-dom";
import {
	A11y,
	Autoplay,
	Keyboard,
	Navigation,
	Pagination,
	Scrollbar
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import portoData from "../../dao/Project";
import Palette from "../utils/Palette";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
export default function OurPortfolio() {
	useEffect(() => {
		window.scroll(0, 0)
	}, [])
	const [isMobile, setIsMobile] = useOutletContext()
    const [showModal, setShowModal] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null); 

    const openModal = (event, videoKey) => {
        setSelectedEvent(event); 
        setSelectedVideo(videoKey); 
        setShowModal(true); 
    };

    const closeModal = () => {
        setSelectedVideo(null); 
        setSelectedEvent(null);
        setShowModal(false); 
    };

	const buttonStyle = {
		textDecoration: "none",
		fontSize: "1.25em",
		fontWeight: 600,
		color: Palette.ORANGE,
		border: "none",
		background: "transparent",
		cursor: "pointer",
		marginTop: "-20px",
	};

	const renderPortfolio = () => {
		return (
			<div className="hero-container">
				<Container>
					<Row>
						<Col md={12}>
							<div className="hero-title d-flex justify-content-center mb-2">
								<h1>Our Portfolio</h1>
							</div>
							<div
								style={{ color: Palette.THEME_WHITE, fontSize: "1.25em" }}
								className="hero-description text-center"
							>
								Explore our impressive portfolio of successful projects that
								showcase Intellivent's expertise in creating exceptional event
								experiences. From conferences to immersive virtual showcases,
								each project reflects our commitment to innovation,
								collaboration, and excellence
							</div>
						</Col>
					</Row>
				</Container>

				<Container>
					<Row className="align-items-center">
						<div className="section-title mt-5">
							<h2>Event Registration & Management System</h2>
						</div>
					</Row>

					<Row className={`mt-1 ${isMobile ? "p-2" : "p-5"}`}>
						{portoData
							.filter((v) => v.type === "event")
							.map((v, i) => {
								return (
									<>
										<Col md={12} xs={12} sm={12} className="mb-5">
											<Card
												style={{
													border: 0,
													borderRadius: 0,
													background: "#1e1e1e"
												}}
											>
												<Row
													className={
														isMobile
															? "flex-column-reverse"
															: i % 2 === 0
															? "flex-row-reverse"
															: ""
													}
												>
													<Col md={6} sm={12} xs={12}>
														<Card.Header
															style={{ background: "transparent", border: 0 }}
														>
															<Card.Title
																className="mt-1"
																style={{
																	fontWeight: "bolder",
																	fontSize: "1.4em",
																	color: Palette.WHITE
																}}
															>
																{v.title}
															</Card.Title>
															<Badge>IRS</Badge>
														</Card.Header>
														<Image
															src={v.imageSmall}
															style={{
																width: isMobile ? 120 : "auto",
																height: isMobile ? 120 : "auto",
																right: i % 2 !== 0 ? "auto" : 0,
																position: "absolute",
																zIndex: 1,
																opacity: 0.2
															}}
														/>
														<Card.Body className="h-100">
															<div
																style={{
																	height: "70%",
																	zIndex: 2,
																	color: Palette.THEME_WHITE,
																	fontSize: "1.25em"
																}}
															>
																{v.description}
															</div>
															{v.url && (
																<div>
																	<Link
																		target={"_blank"}
																		style={{
																			textDecoration: "none",
																			fontSize: "1.25em",
																			fontWeight: 800,
																			color: Palette.ORANGE
																		}}
																		to={v.url}
																	>
																		See Demo
																	</Link>
																</div>
															)}
														</Card.Body>
													</Col>
													<Col md={6} xs={12} sm={12}>
														<Swiper
															keyboard={{
																enabled: true
															}}
															autoplay={{
																delay: 3000 + i * 100 + 500,
																disableOnInteraction: false
															}}
															pagination={{
																paginationClickable: true,
																dynamicBullets: true,
																clickable: true,
																keyboard: {
																	enabled: true
																}
																// progressbarOpposite: true
															}}
															modules={[
																Navigation,
																Pagination,
																Scrollbar,
																A11y,
																Keyboard,
																Autoplay
															]}
															className="mySwiper"
														>
															{v.imageList ? (
																v.imageList?.map((thumb) => {
																	return (
																		<>
																			<SwiperSlide>
																				<Card.Img
																					style={{ borderRadius: 0 }}
																					src={thumb}
																					fluid
																				/>
																			</SwiperSlide>
																		</>
																	)
																})
															) : (
																<Card.Img
																	style={{ borderRadius: 0 }}
																	src={v.image}
																	fluid
																/>
															)}
														</Swiper>
													</Col>
												</Row>
											</Card>
										</Col>
									</>
								)
							})}
					</Row>
				</Container>

				<Container>
					<Row className="align-items-center">
						<div className="section-title mt-5">
							<h2>Event 3D Exhibition</h2>
						</div>
					</Row>

					<Row>
						{_.sortBy(portoData, "order")
							.filter((v) => v.type === "exibition")
							.map((v, i) => {
								return (
									<>
										<Col md={12} className="mb-5">
											<Card
												style={{
													border: 0,
													borderRadius: 0,
													background: "#1e1e1e"
												}}
											>
												<Row
													className={
														isMobile
															? "flex-column-reverse"
															: i % 2 === 0
															? "flex-row-reverse"
															: ""
													}
												>
													<Col md={6}>
														<Card.Header
															style={{ background: "transparent", border: 0 }}
														>
															<Card.Title
																className="mt-1"
																style={{
																	fontWeight: "bolder",
																	color: Palette.WHITE,
																	fontSize: "1.4em"
																}}
															>
																{v.title}
															</Card.Title>
															<Badge bg="danger" variant="danger">
																IVE
															</Badge>
														</Card.Header>
														<Image
															src={v.imageSmall}
															style={{
																width: isMobile ? 120 : "auto",
																height: isMobile ? 120 : "auto",
																right: i % 2 !== 0 ? "auto" : 0,
																position: "absolute",
																zIndex: 1,
																opacity: 0.2
															}}
														/>
														<Card.Body
															style={{
																color: Palette.THEME_WHITE,
																fontSize: "1.25em"
															}}
															className=" h-100"
														>
															<div style={{ height: "70%", zIndex: 2 }}>
																{v.description}
															</div>
															{v.url && 
                                                            <div>
																<Link
																	target={"_blank"}
																	style={{
																		textDecoration: "none",
																		fontSize: "1.25em",
																		fontWeight: 800,
																		color: Palette.ORANGE
																	}}
																	to={v.url}
																>
																	See Demo
																</Link>
															</div>}
														</Card.Body>
													</Col>
													<Col md={6}>
														<Swiper
															keyboard={{
																enabled: true
															}}
															autoplay={{
																delay: 3000 + i * 100 + 500,
																disableOnInteraction: false
															}}
															pagination={{
																paginationClickable: true,
																dynamicBullets: true,
																clickable: true,
																keyboard: {
																	enabled: true
																}
																// progressbarOpposite: true
															}}
															modules={[
																Navigation,
																Pagination,
																Scrollbar,
																A11y,
																Keyboard,
																Autoplay
															]}
															className="mySwiper"
														>
															{v.imageList ? (
																v.imageList?.map((thumb) => {
																	return (
																		<>
																			<SwiperSlide>
																				<Card.Img
																					style={{ borderRadius: 0 }}
																					src={thumb}
																					fluid
																				/>
																			</SwiperSlide>
																		</>
																	)
																})
															) : (
																<Card.Img
																	style={{ borderRadius: 0 }}
																	src={v.image}
																	fluid
																/>
															)}
														</Swiper>
													</Col>
												</Row>
											</Card>
										</Col>
									</>
								)
							})}
					</Row>
				</Container>
				<Container>
					<Row className="align-items-center">
						<div className="section-title mt-5">
							<h2>Event Activation</h2>
						</div>
					</Row>

					<Row>
						{_.sortBy(portoData, "order")
							.filter((v) => v.type === "activation")
							.map((v, i) => {
								return (
									<>
									<Col md={12} className="mb-5">
										<Card
											style={{
												border: 0,
												borderRadius: 0,
												background: "#1e1e1e"
											}}
										>
											<Row
												className={
													isMobile
														? "flex-column-reverse"
														: i % 2 === 0
														? "flex-row-reverse"
														: ""
												}
											>
												<Col md={6}>
													<Card.Header
														style={{ background: "transparent", border: 0 }}
													>
														<Card.Title
															className="mt-1"
															style={{
																fontWeight: "bolder",
																color: Palette.WHITE,
																fontSize: "1.4em"
															}}
														>
															{v.title}
														</Card.Title>
														<Badge bg="danger" variant="danger">
															IVE
														</Badge>
													</Card.Header>
													<Image
														src={v.imageSmall}
														style={{
															width: isMobile ? 120 : "auto",
															height: isMobile ? 120 : "auto",
															right: i % 2 !== 0 ? "auto" : 0,
															position: "absolute",
															zIndex: 1,
															opacity: 0.2
														}}
													/>
													<Card.Body
														style={{
															color: Palette.THEME_WHITE,
															fontSize: "1.25em"
														}}
														className=" h-100"
													>
														<div style={{ height: "70%", zIndex: 2 }}>
															{v.description}
														</div>
														{/* {v.url && ( */}
														<div style={{ display: 'flex', alignItems: 'center' }}>
														<Button
															onClick={() => openModal(v, 'video1')}
															style={buttonStyle}
														>
															See Demo
														</Button>
														<Button
															onClick={() => openModal(v, 'video2')}
															style={buttonStyle}
														>
															See Demo 2
														</Button>
														</div>
														{/* )} */}
													</Card.Body>
												</Col>
												<Col md={6}>
													<Swiper
														keyboard={{
															enabled: true
														}}
														autoplay={{
															delay: 3000 + i * 100 + 500,
															disableOnInteraction: false
														}}
														pagination={{
															paginationClickable: true,
															dynamicBullets: true,
															clickable: true,
															keyboard: {
																enabled: true
															}
															// progressbarOpposite: true
														}}
														modules={[
															Navigation,
															Pagination,
															Scrollbar,
															A11y,
															Keyboard,
															Autoplay
														]}
														className="mySwiper"
													>
														{v.imageList ? (
															v.imageList?.map((thumb) => {
																return (
																	<>
																		<SwiperSlide>
																			<Card.Img
																				style={{ borderRadius: 0 }}
																				src={thumb}
																				fluid
																			/>
																		</SwiperSlide>
																	</>
																)
															})
														) : (
															<Card.Img
																style={{ borderRadius: 0 }}
																src={v.image}
																fluid
															/>
														)}
													</Swiper>
												</Col>
											</Row>
										</Card>
									</Col>
									</>
								)
							})}
					</Row>
					<Modal
						size="lg"
						show={showModal}
						onHide={closeModal}
						centered
						dialogClassName="modal-90w"
						style={{ padding: 0 }}
					>
						<Modal.Body style={{ padding: 0 }}>
							<div style={{ position: 'relative', width: '100%', height: '100vh', overflow: 'hidden' }}>
								<Button
									variant="secondary"
									onClick={closeModal}
									style={{
										position: 'absolute',
										top: 10,
										right: 10,
										zIndex: 10
									}}
								>
									Close
								</Button>
								{selectedVideo === 'video1' && (
									<iframe
										title="Video 1"
										src={selectedEvent?.video1}
										frameBorder="0"
										allowFullScreen
										style={{
											position: 'absolute',
											top: 0,
											left: 0,
											width: '100%',
											height: '100%'
										}}
									></iframe>
								)}
								{selectedVideo === 'video2' && (
									<iframe
										title="Video 2"
										src={selectedEvent?.video2}
										frameBorder="0"
										allowFullScreen
										style={{
											position: 'absolute',
											top: 0,
											left: 0,
											width: '100%',
											height: '100%'
										}}
									></iframe>
								)}
							</div>
						</Modal.Body>
					</Modal>
				</Container>
			</div>
		)
	}

	return (
		<>
			<Container
				style={{
					background: Palette.BLACK_BACKGROUND
				}}
				fluid
			>
				{renderPortfolio()}
			</Container>
		</>
	)
}
