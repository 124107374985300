import { useState, useEffect } from "react";
import Header from "../reusable/Header";
import { Outlet } from "react-router-dom";
import Footer from "../reusable/Footer";
import FloatingWhatsapp from "../reusable/FloatingWhatsapp";


export default function DefaultLayout() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 576)

  useEffect(() => {
    const handleWindowResize = () => {
      setIsMobile(window.innerWidth < 576)
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <>

      <div style={{ fontFamily: "Lato" }}>
        <FloatingWhatsapp />
        <Header isMobile={isMobile} />
        <div style={{ marginTop: isMobile ? 0 : 100 }}>
          <Outlet context={[isMobile, setIsMobile]} />
        </div>
        <Footer isMobile={isMobile} />
      </div>

    </>
  )
}