import { BrowserRouter } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Router from './components/utils/Router';
import FloatingWhatsapp from './components/reusable/FloatingWhatsapp';

function App() {
  return (
    <>
      {/* <FloatingWhatsapp /> */}
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </>

  );
}

export default App;
