import { useRoutes } from "react-router-dom";
import Home from "../pages/Home";
import DefaultLayout from "../layout/DefaultLayout";
import Services from "../pages/Services";
import OurDemo from "../pages/OurProduct";
import ContactUs from "../pages/Contact";
import OurPortfolio from "../pages/Portfolio";
import About from "../pages/About";
import OurProduct from "../pages/OurProduct";
import Sample from "../pages/Sample";
export default function Router() {

  let routesConfig = [
    {
      path: '', element: <DefaultLayout />, children: [
        { path: '/', element: <Home /> },
        { path: '/whatsapp', element: <Home /> },
        // { path: '/services', element: <Services />},
        { path: '/product', element: <OurProduct /> },
        { path: '/product/:key', element: <OurProduct /> },
        { path: '/contact', element: <ContactUs /> },
        { path: '/portfolio', element: <OurPortfolio /> },
        { path: '/about', element: <About /> },
      ]
    },
    { path: '/sample', element: <Sample /> },
  ]

  const routes = useRoutes(routesConfig);

  return routes;
}