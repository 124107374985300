import { Col, Container, Row, Image, InputGroup, Button, Form } from "react-bootstrap"
import PrimaryButton from "../reusable/PrimaryButton"
import Palette from "../utils/Palette"
import * as Yup from "yup";
import { useEffect, useState } from "react"
import Contact from "../../dao/Contact"
import { useFormik } from "formik";
import Swal from 'sweetalert2'
export default function ContactUs() {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const schema = Yup.object().shape({
        full_name: Yup.string().required('Name is required'),
        email: Yup.string().email().required('Email is required'),
        phone_number: Yup.number().required('Phone Number/Whatsapp is required'),
        subject: Yup.string().required('Subject is required'),
        message: Yup.string().required('Message is required')
    });
    const formik = useFormik({
        initialValues: {
            full_name: "",
            email: "",
            phone_number: "",
            subject: "",
            message: ""
        },
        validationSchema: schema,
        onSubmit: async (values, { resetForm }) => {
            try {
                await Contact.sendContact(values)
            } catch (e) {
            }
            resetForm();
            Swal.fire({
                title: 'success!',
                text: 'Thank you, we will provide feedback from your message soon.',
                icon: 'success',
                confirmButtonText: 'Finish'
            })
            // setFormState(values);
        }
    });
    
    const renderContact = () => {

        return (
            <div className="hero-container">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="hero-title d-flex justify-content-center mb-2">
                                <h1>
                                    Contact Us
                                </h1>
                            </div>
                            <div className="hero-description text-center" style={{ fontSize: '1.25em' }}>
                                Feel free to reach out to us for any inquiries or to discuss your upcoming event. Our team is here to assist you.                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className="pt-5 d-flex justify-content-center">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.926598612454!2d106.61674527459022!3d-6.273382261410546!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69fd0822705115%3A0xbf34e93330144004!2sKomune%20Studio%20Software%20House!5e0!3m2!1sen!2sid!4v1691380299584!5m2!1sen!2sid" width="100%" height="450" style={{ border: 0 }} allowFullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

                    {/* <Image src={contact} fluid></Image> */}
                </Container>

                <Container>
                    <Row className="pt-5 pb-5">
                        <Col md={6}>
                            <div className="section-title-contact text-left mt-5 ">
                                <h2>Location</h2>
                            </div>
                            <div style={{ color: Palette.THEME_WHITE, fontSize: '1.25em' }} className="mb-2">
                                Ruko Aniva Grande GC No. 19, Paramount Land, Tangerang, Banten 15334, Indonesia
                            </div>
                            <div className="section-title-contact text-left mt-5 ">
                                <h2>Email</h2>
                            </div>
                            <div style={{ color: Palette.THEME_WHITE, fontSize: '1.25em' }} className="mb-2">
                                intellivent.id@gmail.com
                            </div>
                            <div className="section-title-contact text-left mt-5 ">
                                <h2>Call</h2>
                            </div>
                            <div style={{ color: Palette.THEME_WHITE, fontSize: '1.25em' }} className="mb-2">
                                +62&nbsp; 8138&nbsp; 3798&nbsp; 649
                            </div>
                        </Col>
                        <Col md={6}>

                            <Form onSubmit={formik.handleSubmit} className="form-contact mt-5">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Control
                                        name="full_name"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.full_name}
                                        className="contact-form-input" type="text" placeholder="Name" />

                                    <Form.Text className="text-danger">
                                        {formik.touched.full_name && formik.errors.full_name ? (
                                            <div className="text-danger">{formik.errors.full_name}</div>
                                        ) : null}
                                    </Form.Text>

                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Control
                                        name="phone_number"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.phone_number}
                                        className="contact-form-input" type="number" placeholder="Whatsapp/Phone Number" />

                                    <Form.Text className="text-danger">
                                        {formik.touched.phone_number && formik.errors.phone_number ? (
                                            <div className="text-danger">{formik.errors.phone_number}</div>
                                        ) : null}
                                    </Form.Text>

                                </Form.Group>


                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Control
                                        name="email"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                        className="contact-form-input" type="text" placeholder="Email" />

                                    <Form.Text className="text-danger">
                                        {formik.touched.email && formik.errors.email ? (
                                            <div className="text-danger">{formik.errors.email}</div>
                                        ) : null}
                                    </Form.Text>

                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Control
                                        name="subject"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.subject}
                                        className="contact-form-input" type="text" placeholder="Subject" />

                                    <Form.Text className="text-danger">
                                        {formik.touched.subject && formik.errors.subject ? (
                                            <div className="text-danger">{formik.errors.subject}</div>
                                        ) : null}
                                    </Form.Text>

                                </Form.Group>



                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        name="message"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.message}
                                        className="contact-form-input"

                                        placeholder="Message" />

                                    <Form.Text className="text-danger">
                                        {formik.touched.message && formik.errors.message ? (
                                            <div className="text-danger">{formik.errors.message}</div>
                                        ) : null}
                                    </Form.Text>

                                </Form.Group>
                                <PrimaryButton type="submit" className="button-width" text={'SEND'} />
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div >
        )
    }

    return (
        <>
            {renderContact()}
        </>
    )
}