import clientLogoNarabahasa from '../assets/clients/client_narabahasa.png'
import clientLogoPatjarMerah from '../assets/clients/client_patjarmerah.png'
import clientLogoIslamicBookfair from '../assets/clients/client_islamic_bookfair.png'
import clientLogoPestaBoneka from '../assets/clients/client_pesta_boneka.png'
import clientLogoPetFest from '../assets/clients/client_pet_fest.png'
import clientLogoPuppetTheatre from '../assets/clients/client_puppet_theatre.png'
import clientLogoSGU from '../assets/clients/client_sgu.png'
import clientLogoCbFest from '../assets/clients/client_cb_fest.png'
import clientLogoFestivalBukuAsia from '../assets/clients/client_festival_buku.png'

export default [
    {
        title: 'Narabahasa',
        image: clientLogoNarabahasa
    },
    {
        title: 'Patjarmerah',
        image: clientLogoPatjarMerah
    },
    {
        title: 'PetFest',
        image: clientLogoPetFest
    },
    {
        title: 'Pesta Boneka',
        image: clientLogoPestaBoneka
    },
    {
        title: 'PapermoonPuppetTheatre',
        image: clientLogoPuppetTheatre
    },
    {
        title: 'SGU',
        image: clientLogoSGU
    },
    {
        title: 'CBFest',
        image: clientLogoCbFest
    },
    {
        title: 'Festival Buku Asia',
        image: clientLogoFestivalBukuAsia
    },
    {
        title: 'Islamic BookFair',
        image: clientLogoIslamicBookfair
    }
]